function formatTime(time) {
    let formattedTime = '';

    if (/\d\d:\d\d:\d\d/.test(time)) {
        const [h, m] = time.split(':');
        const amPm = h < 12 ? 'AM' : 'PM';

        formattedTime = `${h % 12}:${m} ${amPm}`;
    }

    return formattedTime;
}

function formatDate(date) {
    const dayjs = require('dayjs');

    return dayjs(date).format('DD/MM/YYYY');
}

function formatDateTime(datetime) {
    const dayjs = require('dayjs');

    return dayjs(datetime).format('DD/MM/YYYY HH:mm');
}

function isNumeric(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '' && !isNaN(value);
}

function isPositiveNumber(value) {
    return isNumeric(value) && value > 0;
}

function isZeroOrPositive(value) {
    return value === '' || (isNumeric(value) && value >= 0);
}

function formatCurrency(value) {
    if (!isNumeric(value)) {
        value = 0;
    }

    return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
}

module.exports = {
    formatTime,
    isNumeric,
    isPositiveNumber,
    isZeroOrPositive,
    formatCurrency,
    formatDate,
    formatDateTime
};